import { ReactElement, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import Hourglass from '../components/common/Hourglass';
import LeagueDetails from '../components/league/LeagueDetails';
import LeagueEditor from '../components/league/LeagueEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import League from '../types/League';

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { setActions, setPageName } = useApp();
  const [isLeagueLoading, setIsLeagueLoading] = useState(true);
  const { getLeague, isLeagueAdmin, isOrgAdmin } = useData();
  const { orgId, leagueId } = useParams();
  const navigate = useNavigate();
  const [league, setLeague] = useState<League | null>(null);

  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);
  
  useEffect(() => {
    async function fetchLeague() {
      if (orgId && leagueId) {
        const fetchedLeague = await getLeague(orgId, leagueId);
        fetchedLeague && setLeague(fetchedLeague);
      }
    }
    fetchLeague();
  }, [orgId, leagueId, getLeague]);

  useEffect(() => {
    if (league) {
      setIsLeagueLoading(false);
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId, league.orgId)) {
        setActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [league, isLeagueAdmin, isOrgAdmin, setActions, setIsLeagueLoading, setPageName]);

  const handleEditClick = () => {
    if (league) {
      navigate('/league-edit/' + league.orgId + '/' + league.itemId, { replace: true });
    }
    setIsEditMode(true);
  };

  if (isLeagueLoading) {
    return <Hourglass />;
  } else if (league) {
    if (isLeagueAdmin(league.itemId, league.orgId)) {
      return (
        <Box>
          {isEditMode ? <LeagueEditor league={league} isNewLeague={false} /> : <LeagueDetails league={league} />}
          <Fab
            variant="extended"
            color="primary"
            aria-label="add event"
            component={Link}
            to={`/event-create/${league.orgId}/${league.itemId}`}
          >
            <AddIcon />
            Add event
          </Fab>
        </Box>
      );
    } else {
      return <LeagueDetails league={league} />;
    }
  } else {
    return <></>;
  }
}
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) &&
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&
