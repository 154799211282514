import { ReactElement } from "react";

import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useData } from "../../contextProviders/DataProvider";
import useOrgs from "../../dataProviders/useOrgs";
import Hourglass from "../common/Hourglass";

export type OrgSelectorProps = {
  handleChange: (orgId: string) => void;
  value: string; // initial orgId
}

export default function OrgSelector(props: OrgSelectorProps): ReactElement {
  const { handleChange, value } = props;
  const { adminOrgs } = useData();
  const { orgs, isOrgsLoading, orgsError } = useOrgs();

  if (value) {
    if (orgs) {
      if (adminOrgs.length === 1) {
        return (
          <>
            <Typography variant="label">Organization</Typography>
            <Typography variant="body1">{orgs?.find((org) => org.itemId === value)?.name}</Typography>
          </>
        );
      } else if (adminOrgs.length > 1) {
        return (
          <>
            <TextField
              id="org"
              label="Organization"
              autoFocus={false}
              select
              aria-label="organization"
              aria-required="false"
              value={value}
              fullWidth
              onChange={({ target }) => handleChange(target.value)}
            >
              {adminOrgs?.map((orgId) => (
              <MenuItem key={orgId} value={orgId}>
                  {orgs?.find((org) => org.itemId === orgId)?.name}
                </MenuItem>
              ))}
            </TextField>
          </>
        );
      } else { // adminOrgs.length == 0
        <>
        <Typography variant="label">Organization</Typography>
          <Typography variant="body1">You are an admin for no organizations</Typography>
        </>
      }
    } else if (isOrgsLoading) {
      return (<Hourglass />);
    } else if (orgsError) {
      return <Alert severity="error">Error loading organizations {orgsError.message}</Alert>;
    }
    return <></>;
  } else {
    return <Alert severity="error">You must select an organization</Alert>;
  }
}
