import { and, collectionGroup, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import League from "../types/League";

export default function useLeagues() {
  const { isGod } = useAuth();
  const { userProfile } = useData();

  // *************** League loaders ***************
  const qLeagues = userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? query(
    collectionGroup(firestore, "league") as Query<League>,
    and(where("orgId", "in", userProfile.orgIds),
      where("isTest", "in", [isGod, false, null])), 
    orderBy("name")) : null;
  const [leagues, isLeaguesLoading, leaguesError] = useCollectionData<League>(qLeagues);

  return { leagues, isLeaguesLoading, leaguesError };   
}
