import { 
  ReactElement
} from 'react';

import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import PersonDirectory from '../components/person/PersonDirectory';
import PersonGhinCsvUpdate from '../components/person/PersonGhinCsvUpdate';
import { useData } from "../contextProviders/DataProvider";

export default function Guests(): ReactElement {
  const { adminOrgs } = useData();

  if (adminOrgs.length > 0) {
    return (
      <Box>
        <PersonDirectory isActive={false}/>
        <PersonGhinCsvUpdate />
        <Fab variant="extended" color="primary" aria-label="invite guest" component={Link} to="/invite-guests">
          <AddIcon />
          Add guest
        </Fab>
      </Box>
    );
  } else {
    return (
      <></>
    );
  }  
}