import { and, collection, CollectionReference, orderBy } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Org from "../types/Org";

export default function useOrgs() {
  const { isGod } = useAuth();
  const { userProfile } = useData();

  // *************** Org loaders ****************
  const qOrgs = userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? query(
    collection(firestore, 'org/') as CollectionReference<Org>, 
    and(where("itemId", "in", userProfile.orgIds),
      where("isTest", "in", [isGod, false, null])), 
    orderBy("name")) : null;
  const [orgs, isOrgsLoading, orgsError] = useCollectionData(qOrgs);

  return { orgs, isOrgsLoading, orgsError };   
}
