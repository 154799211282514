import { ReactElement, useState } from 'react';

import { UploadResult } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import UserProfile from '../../types/UserProfile';
import { FromDayJS, ToDayJS } from '../../utils/utils';
import GratiImageUpload from '../common/GratiImageUpload';

import PersonLeagueEditor from './PersonLeagueEditor';

interface PersonEditorProps {
  profile: UserProfile;
  isNewProfile: boolean;
  onCancel?: () => void;
  onSubmit?: (profile: UserProfile) => void;
}

export default function PersonEditor(props: PersonEditorProps): ReactElement {
  const { isNewProfile, onCancel, onSubmit } = props;
  const { isGod } = useAuth();
  const { adminOrgs, isOrgAdmin, updateMembership, userProfile } = useData();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile>(props.profile);

  const handleImageUploaded = (snapshot: UploadResult) => {
    if (profile) {
      setProfile({ ...profile, picture: snapshot.ref.name });
    }
    console.log('Image uploaded: ' + snapshot.ref.name);
  };

  const handleMembershipChange = () => {
    if (profile) {
      updateMembership({ itemId: profile.itemId, isActive: true, isMember: true, isAdmin: false });
      setProfile({ ...profile, isActive: !profile.isActive });
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(profile);
    } else {
      console.log('No submit handler provided.');
    }
  };

  if (profile) {
    // You can only edit your own profile unless you are an admin
    if (profile.itemId === userProfile?.itemId || (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0]))) {
      return (
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container sx={{display: "flex"}}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Stack spacing={2} sx={{display: "flex", flexDirection: "column", pr: 2}}>
                <Typography align="left" variant="title" gutterBottom>
                  Profile Information
                </Typography>
                <TextField
                  id="name"
                autoFocus={true}
                fullWidth
                required
                label="Name"
                aria-label="Your name"
                aria-required="true"
                defaultValue={profile.name}
                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
              />
              <GratiImageUpload
                onImageUploaded={handleImageUploaded}
                storageImageFileName={profile.itemId}
                label="Profile Picture"
              />
              <TextField
                id="ghin"
                fullWidth
                type="number"
                label="GHIN"
                aria-label="Golf Handicap Index Number"
                aria-required="true"
                inputProps={{ maxLength: 7 }}
                defaultValue={profile.ghin}
                onChange={(e) => setProfile({ ...profile, ghin: (+e.target.value).toString() })}
              />
              <TextField
                id="index"
                fullWidth
                type="number"
                label="index (from GHIN)"
                disabled
                InputProps={{ inputProps: { min: 0, max: 45, step: 0.1, readOnly: true } }}
                aria-label="GHIN index"
                aria-required="true"
                defaultValue={profile.index}
                onChange={(e) => setProfile({ ...profile, index: e.target.value })}
              />
              <DatePicker
                label="Birthday"
                aria-label="Birthday"
                aria-required="true"
                defaultValue={ToDayJS(profile.birthday)}
                onChange={(e) => {
                  e && setProfile({ ...profile, birthday: FromDayJS(e) });
                }}
              />
              <FormControl>
                <FormLabel id="gender-radio-buttons-group">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-radio-buttons-group"
                  name="gender-radio-buttons-group"
                  defaultValue={profile.gender}
                  onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
                >
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Stack spacing={2}>
                <Typography align="left" variant="title" gutterBottom>
                  Contact Information
                </Typography>
                <TextField
                  id="email"
                  required
                  fullWidth
                  label="Email"
                  aria-label="Email address"
                  aria-required="true"
                  defaultValue={profile.email}
                  onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                />
                <TextField
                  id="phone"
                  required
                  fullWidth
                  label="Phone"
                  aria-label="Phone number"
                  aria-required="true"
                  defaultValue={profile.phone}
                  onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
                />
                <TextField
                  id="mailingAddress"
                  fullWidth
                  label="Mailing Address"
                  aria-label="Mailing address"
                  defaultValue={profile.mailingAddress}
                  onChange={(e) => setProfile({ ...profile, mailingAddress: e.target.value })}
                />

                <TextField
                  id="localAddress"
                  fullWidth
                  label="Local Address"
                  aria-label="Local address"
                  defaultValue={profile.localAddress}
                  onChange={(e) => setProfile({ ...profile, localAddress: e.target.value })}
                />
                <PersonLeagueEditor person={profile} />

              </Stack>
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ mt: 2 }}>
              <Stack direction="row" spacing={2}>
                <Button size="medium" variant="contained" startIcon={<SaveIcon />} type="submit">
                  {isNewProfile ? 'Create' : 'Update'}
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
            {isOrgAdmin(adminOrgs[0]) && (
            <Grid gap={2} size={{ xs: 12 }} sx={{ mt: 2 }}>
              <Stack 
                direction="row" 
                justifyContent="flex-start" 
                alignItems="center" spacing={2} 
                sx={{ backgroundColor: "tertiary.container", color: "tertiary.onContainer" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id={'actions' + profile.itemId}
                        checked={profile.isActive}
                        color="secondary"
                        onChange={handleMembershipChange}
                      />
                    }
                    label="Active"
                  />
                </FormGroup>
                {isGod && (
                  <FormGroup>
                    <FormControlLabel
                      id="isTest"
                      value="top"
                      aria-label="Is a test profile"
                      aria-required="false"
                      label="Test?"
                      control={
                        <Switch
                          id={'isTest' + profile.isTest}
                          checked={profile.isTest}
                          color="secondary"
                          onChange={(e) => setProfile({ ...profile, isTest: e.target.checked })}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </Stack>
            </Grid>)}
          </Grid>
        </Box>
      );
    } else {
      // todo: Add a message that you can't edit this profile
      navigate(-1);
      return <></>;
    }
  } else {
    return <></>;
  }
}
