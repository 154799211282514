import { ReactElement, useEffect, useState } from 'react';

import { doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref } from 'firebase/storage';

import { useNavigate, useParams } from 'react-router-dom';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';

import Hourglass from '../components/common/Hourglass';
import PersonEditor from '../components/person/PersonEditor';
import PersonLeagueList from '../components/person/PersonLeagueList';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { cloudFunctions } from '../firebase';
import UserProfile from '../types/UserProfile';
import { DisplayDate } from '../utils/utils';

interface PersonPageProps {
  isEditMode?: boolean;
}

export default function PersonPage(props: PersonPageProps): ReactElement {
  const { setActions, setPageName } = useApp();
  const { isGod, isRevealingGodsSecrets, isLoggedIn, imagesRef, user } = useAuth();
  const { adminOrgs, isOrgAdmin, updateUserProfile, userProfilesCol } = useData();

  const { profileId } = useParams() as { profileId: string };
  // if no profileId is passed in, show the logged-in user's profile
  const activeProfileId = profileId ? profileId : user?.uid;
  const navigate = useNavigate();

  // The profile that is being edited is the active profile
  const [activeProfilePic, setActiveProfilePic] = useState('');
  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [activeProfile, isProfileLoading, profileLoadingError] = useDocumentData<UserProfile>(
    doc(userProfilesCol, activeProfileId)
  );

  useEffect(() => {
    if (activeProfile) {
      if (activeProfile.picture) {
        getDownloadURL(ref(imagesRef, activeProfile.picture))
          .then((url) => {
            setActiveProfilePic(url);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setPageName(activeProfile.name);
      if (!isEditMode && (isGod || (activeProfile && activeProfile.itemId === user?.uid))) {
        setActions([{ name: 'Edit', handleClick: handleEditClick, icon: EditIcon }]);
      }
      console.log(activeProfile.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProfile, imagesRef, isEditMode, isGod, isProfileLoading, profileLoadingError, setActions, setPageName]);

  const handleEditClick = () => {
    navigate('/profile-edit/' + activeProfileId, { replace: true });
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    navigate('/profile/' + activeProfileId, { replace: true });
    setIsEditMode(false);
  };

  const handleSubmitEdit = (updatedProfile: UserProfile) => {
    if (activeProfile) {
      console.log('Submit: ', updatedProfile.picture, updatedProfile.pictureName);
      updateUserProfile(activeProfile.itemId, updatedProfile);
    }
    setIsEditMode(false);
  };

  // This call will only work if the user is already a god.
  const handleDeify = () => {
    if (activeProfile) {
      const deifyUser = httpsCallable(cloudFunctions, 'makeUserAGod');
      deifyUser({ email: activeProfile?.email })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('No active profile');
    }
  };

  const ProfileView = (props: { profile: UserProfile }): ReactElement => {
    const profile = props.profile;

    return (
      <Container disableGutters className="SectionContainer">
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid size={{ xs: 12, sm: 4, md: 2}}>
            {activeProfilePic && (
              <Box component="img" src={activeProfilePic} alt="profile picture" sx={{ width: '100%' }} />
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 8, md: 10 }}>
            <Stack direction="column" spacing={2} className={profile.isTest ? "test" : ""}>
              <Typography variant="headline">
                {profile.name}
              </Typography>
              {profile.ghin && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center" }}>
                  <Typography variant="label">
                    GHIN:
                  </Typography>
                  <Typography variant="body1">
                    {profile.ghin}
                  </Typography>
                  <Typography variant="label">
                    Index:
                  </Typography>
                  <Typography variant="body1">
                    {profile.index}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={1} sx={{alignItems: "center" }}>
                <Typography variant="label">
                  email:
                </Typography>
                <Typography variant="body1">
                  {profile.email}
                </Typography>
                {profile.phone && profile.phone.length > 1 && (
                  <Stack direction="row" spacing={1} sx={{alignItems: "center" }}>
                    <Typography variant="label">
                      Phone:
                    </Typography>
                    <Typography variant="body1">
                      {profile.phone}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              {profile.birthday && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center" }}>
                  <Typography variant="label" component="span">
                    Birthday:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {DisplayDate(profile.birthday.toDate(), 'America/Los_Angeles', 'MMMM D')}
                  </Typography>
                </Stack>
              )}
              {profile.gender && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center", }}>
                  <Typography variant="label" component="span">
                    Gender:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {profile.gender}
                  </Typography>
                </Stack>
              )}
              {profile.mailingAddress && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center", }}>
                  <Typography variant="label">
                    Mailing Address:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {profile.mailingAddress}
                  </Typography>
                </Stack>
              )}
              {profile.localAddress && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center", }}>
                  <Typography variant="label">
                    Local Address:
                  </Typography>
                  <Typography variant="body1" component="span">
                    {profile.localAddress}
                  </Typography>
                </Stack>
              )}
              <PersonLeagueList person={profile} />
              {isRevealingGodsSecrets && profile.isTest && (
                <Typography variant="body1">
                  Test Profile
                </Typography>
              )}
              {isRevealingGodsSecrets && (
                <Stack direction="row" spacing={1} sx={{alignItems: "center" }}>
                  <Typography variant="body1" component="span">
                    User Id
                  </Typography>
                  <Typography variant="body1" component="span">
                    {profile.itemId}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
        {isRevealingGodsSecrets && (
          <Button type="button" size="medium" variant="outlined" onClick={handleDeify}>
            Deify
          </Button>
        )}
      </Container>
    );
  };

  if (activeProfile) {
    if (
      (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) ||
      (activeProfile && user && activeProfile.itemId === user.uid)
    ) {
      return (
        <Container disableGutters className="SectionContainer">
          {isEditMode ? (
            <PersonEditor
              profile={activeProfile}
              isNewProfile={false}
              onCancel={handleCancelEdit}
              onSubmit={handleSubmitEdit}
            />
          ) : (
            <ProfileView profile={activeProfile} />
          )}
        </Container>
      );
    } else {
      return <ProfileView profile={activeProfile} />;
    }
  } else if (isLoggedIn) {
    return <Hourglass />;
  } else {
    return <Container />;
  }
}
