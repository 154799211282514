import { 
  ReactElement,  
  useState,
} from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import DeleteIcon from "@mui/icons-material/Delete";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useData } from "../../contextProviders/DataProvider";
import Season from "../../types/Season";
import { 
  FromDayJS,
  ToDayJS 
} from "../../utils/utils";

interface SeasonEditorProps {
  season: Season;
  onChange?: (value: Season, reason: string) => void;
}

export default function SeasonEditor(props: SeasonEditorProps): ReactElement {  
  const { isLeagueAdmin, isOrgAdmin } = useData();
  const [season, setSeason] = useState<Season>(props.season);
  const [deleteConfirm, setDeleteConfirm] = useState<string>("");

  const handleSeasonDelete = () => {
    if (props.onChange) {
      props.onChange(season, "delete");
    }
  };

  const handleSeasonChange = (changes: Partial<Season>) => {
    setSeason({...season, ...changes});
  };

  // TODO: This writes to firestore too often because onBlur occurs when Picker appears.
  const onBlur = (reason: string) => {
    if (props.onChange) {
      if ((props.season.name !== season.name) ||
          !(props.season.startTime.isEqual(season.startTime)) ||
          !(props.season.endTime.isEqual(season.endTime))) {
        console.log("Change: ", season.startTime.isEqual(props.season.startTime))
        props.onChange(season, reason);
      }
    // TODO: Uncontrolled.  
    } else {
      console.log("No onChange handler");
    }
  };

  if (season && (isOrgAdmin(season.orgId) || isLeagueAdmin(season.leagueId, season.orgId))) {
    return (
      <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 3}} onBlur={() => onBlur("update")}>
        <TextField 
          fullWidth
          label="Name" 
          variant="outlined" 
          value={season.name}
          sx={{width: "34%",flexGrow: 1}}
          onChange={(e) => handleSeasonChange({name: e.target.value})}
        />
        <DatePicker
          label="Start date"
          aria-label="start date"
          aria-required="true"
          format="MM/DD/YY"
          value={ToDayJS(season.startTime)}
          sx={{width: "33%", flexGrow: 0}}
          onAccept={(dayJS) => 
            setSeason(
              {...season,
                startTime: FromDayJS(dayJS), 
                endTime: FromDayJS(dayJS) > season.endTime ? FromDayJS(dayJS) : season.endTime
              }
            )
          }
        />
        <DatePicker
          label="End date"
          aria-label="End date"
          aria-required="true"
          format="MM/DD/YY"
          value={ToDayJS(season.endTime)}
          minDate={ToDayJS(season.startTime)}
          sx={{width: "33%", flexGrow: 0}}
          onAccept={(dayJS) => setSeason({...season, endTime: FromDayJS(dayJS)})}
        />
        {!deleteConfirm && <IconButton aria-label="delete" onClick={() => setDeleteConfirm(season.itemId)}>
          <DeleteIcon />
        </IconButton>}

        {deleteConfirm === season.itemId && (
          <span>
            <Button variant="outlined" size="large" onClick={() => handleSeasonDelete()}>
              Confirm
            </Button>
            <Button size="large" color="secondary" onClick={() => setDeleteConfirm("")}>
              Cancel
            </Button>
          </span>
        )}
      </Stack>);
    } else {
      return <></>;
    }
}