import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import MapIcon from '@mui/icons-material/Map';
import WifiOffIcon from '@mui/icons-material/WifiOff';

interface GratiMapFallbackProps {
  height?: string | number;
  width?: string | number;
}

export default function GratiMapFallback({ height = '100%', width = '100%' }: GratiMapFallbackProps) {
  return (
    <Paper
      sx={{
        height,
        width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'surface.main',
        border: 1,
        borderColor: 'border.main',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <MapIcon sx={{ fontSize: 48, color: 'surface.contrastText', mb: 1 }} />
        <WifiOffIcon sx={{ fontSize: 24, color: 'surface.contrastText', mb: 2 }} />
        <Typography variant="body1" color="surface.contrastText">
          Map unavailable while offline
        </Typography>
      </Box>
    </Paper>
  );
}