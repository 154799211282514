import { 
  ReactElement, 
  useEffect 
} from 'react';

import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';

import { useAuth } from "../contextProviders/AuthProvider";

export default function Logout(): ReactElement {
  const navigate = useNavigate();
  const { signOutUser } = useAuth();
  
  useEffect(() => {
    try {
      signOutUser();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }, [signOutUser, navigate]);

  return (
    <Container>
      { <></>}
    </Container>
  );
};