import { 
  createContext,
  ReactElement, 
  ReactNode, 
  useContext,
  useEffect,
  useState,
 } from 'react';

 import { 
   and,
   arrayUnion,
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc, 
  doc, 
  DocumentData,
  getDoc,
  getDocs, 
  query,
  Query,
  setDoc, 
  Timestamp,
  updateDoc,
  where, 
} from 'firebase/firestore';
import { 
  deleteObject, 
  ref,
  uploadBytes, 
} from 'firebase/storage';
 
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import { firestore } from '../firebase';
import Application from '../types/Application';
import Course from '../types/Course';
import Event from '../types/Event';
import Inquiry from '../types/Inquiry';
import League from '../types/League';
import Membership from '../types/Membership';
import Org from '../types/Org';
import Person from '../types/Person';
import Registration from '../types/Registration';
import Result from '../types/Result';
import Season from '../types/Season';
import UserProfile from '../types/UserProfile';
import { CurrentDateTime } from "../utils/utils";

import { useAuth } from "./AuthProvider";

export interface DataProviderProps {
  children?: ReactNode
}

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export interface DataContextModel {
  // User Profile functions
  userProfilesCol: CollectionReference<UserProfile>;
  updateUserProfile: (id: string, profile: Partial<UserProfile>, picFile?: File | null) => Promise<void>;
  updateUserIndex: (ghin: string, index: string) => Promise<void>;

  // User state functions
  userProfile: UserProfile | undefined; 
  isUserActive: boolean;
  isUserLoading: boolean;
  isAdminDisabled: boolean;
  isLeagueAdmin: (leagueId: string, orgId: string) => boolean;
  isOrgAdmin: (orgId: string) => boolean;
  adminDisabled: (disable: boolean) => void;
  adminOrgs: string[];
  adminLeagues: string[];
  memberOrgs: string[];

  // Org functions
  getOrg: (orgId: string) => Promise<Org | null>;
  applicationsCol: CollectionReference<Application>;
  inquiriesCol: CollectionReference<Inquiry>;

  // Inquiry functions
  addInquiry: (name: string, email: string, phone: string) => Promise<void>;
  
  // Application functions
  addApplication: (
    orgId: string, 
    name: string, 
    mailingAddress: string, 
    email: string, 
    ghin: string,
    phone: string,
    isTest?: boolean,
    ) => Promise<void>;
  deleteApplication: (appId: string) => Promise<void>;
  updateApplication: (appId: string, isApproved: boolean, isRejected: boolean) => Promise<void>;
  
  // Membership functions
  memberships: Membership[] | undefined;
  updateMembership: (membership: Partial<Membership>) => Promise<void>;
  addLeagueMembership: (league: League, memberId: string) => Promise<void>;
  // getMemberships: (orgId: string) => Promise<Membership[]>;

  // League functions
  getLeague: (orgId: string, leagueId: string) => Promise<League | null | undefined>;
  addLeague: (league: League, isTest?: boolean) => Promise<League | null>;
  deleteLeague: (league: League) => Promise<void>;
  updateLeague: (league: Partial<League>) => Promise<void>;

  // Season functions
  getSeason: (league: League, seasonId: string) => Promise<Season | null | undefined>;
  addSeason: (season: Partial<Season>) => Promise<Season | null>;
  addStandingToSeason: (season: Season, standing: Result) => Promise<void>;
  deleteSeason: (season: Season) => Promise<void>;
  updateSeason: (season: Partial<Season>) => Promise<void>;

  // Event functions
  getEvent: (orgId: string, eventId: string) => Promise<Event | null>; 
  addEvent: (event: Event, isTest?: boolean) => Promise<Event | null>;
  deleteEvent: (event: Event) => Promise<void>;
  updateEvent: (event: Partial<Event>) => Promise<void>;
  // copyEventToOrg: (eventId: string, orgId: string) => Promise<boolean>;

  // Registration functions
  addEventRegistration: (event: Event, isRegistering: boolean, personToRegister?: Person) => Promise<Registration | null>;
  updateEventRegistration: (event: Event, playerId: string, registration: Partial<Registration>) => Promise<void>;

  // Course functions
  getCourse: (courseId: string) => Promise<Course | null>;
  addCourse: (course: Course, isTest?: boolean) => Promise<Course | null>;
  deleteCourse: (course: Course) => Promise<void>;
  updateCourse: (course: Partial<Course>) => Promise<void>;
}

export const DataContext = createContext<DataContextModel>(
  {} as DataContextModel,
);

export function useData(): DataContextModel {
  return useContext(DataContext)
}

export const DataProvider = ({ children }: DataProviderProps): ReactElement => {
  const { auth, imagesRef, isGod, isLoggedIn, user } = useAuth();

  // *************** User Profile ****************
  const userProfilesCol = createCollection<UserProfile>("profile");
  const [userProfile, isUserLoading, userError] = useDocumentData<UserProfile>(
    isLoggedIn && user ? doc(userProfilesCol, user.uid) : null
  );

  // *************** Membership loaders ****************
  const qMemberships = userProfile ? query(
    collectionGroup(firestore, "membership") as Query<Membership>,
    and(where("itemId", "==", userProfile.itemId),
      where("orgId", "in", userProfile.orgIds))) : null;
  const [memberships, isMembershipsLoading, membershipsError] = useCollectionData<Membership>(
    isLoggedIn ? qMemberships : null
  );

  const applicationsCol = createCollection<Application>("application");
  const inquiriesCol = createCollection<Inquiry>("inquiry");

  // *************** Admin ****************
  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const [adminOrgs, setAdminOrgs] = useState<string[]>([]);
  const [memberOrgs, setMemberOrgs] = useState<string[]>([]);
  const [adminLeagues, setAdminLeagues] = useState<string[]>([]);

  useEffect(() => {
    if (isUserLoading) {
      console.log("Loading user profile");
    } else if (userProfile) {
      console.log("User profile loaded: " + userProfile.name);
      console.log("userProfile.orgIds: " + userProfile.orgIds);
    } else if (userError) {
      console.log("Error loading user profile: " + userError); 
    } else {
      console.log("User profile unloaded.");
    }
  }, [userProfile, isUserLoading, userError]);

  useEffect(() => {
    if (isMembershipsLoading) {
      console.log("Loading memberships");
    } else if (memberships && memberships.length > 0) {
      console.log("memberships: " + memberships.length);
      const adminOrgs = memberships.filter(membership => 
        membership.isActive && membership.isAdmin
      ).map(membership => membership.orgId);
      setAdminOrgs(adminOrgs);
      if (adminOrgs.length > 0) {
        console.log("adminOrgs: " + adminOrgs);
      } else {
        console.log("No admin orgs found.");
      }
      const adminLeagues = memberships?.filter(membership => 
        membership.isActive && membership.leagueAdmins
      ).flatMap(membership => membership.leagueAdmins || []);
      setAdminLeagues(adminLeagues || []);
      if (adminLeagues && adminLeagues.length > 0) {
        console.log("adminLeagues: " + adminLeagues);
      } else {
        console.log("No league admins found.");
      }
      console.log("membership[0]: " + memberships[0].orgId + memberships[0].isActive + " " + memberships[0].isMember);
      const memberOrgsList = Array.from(memberships)
        .filter(membership => membership.isActive && membership.isMember)
        .map(membership => membership.orgId);
      console.log("memberOrgsList: ", memberOrgsList); // Debug log
      setMemberOrgs(memberOrgsList);
    }
    if (membershipsError) {
      console.log("Error loading memberships: " + membershipsError);
    }
  }, [memberships, isMembershipsLoading, membershipsError]);

  // *************** Admin ****************  
  function isOrgAdmin(orgId: string): boolean {
    if (isGod) {
      return true;
    } else if (userProfile && !isMembershipsLoading && memberships && !isAdminDisabled) {
      if (adminOrgs.find(org => org === orgId)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false; 
    } 
  }

  function isLeagueAdmin(leagueId: string, orgId: string): boolean {
    if (isGod) {
      return true;
    } else if (userProfile && !isMembershipsLoading && memberships && !isAdminDisabled) {
      if (userProfile && !isMembershipsLoading && memberships && !isAdminDisabled) {
        if (adminOrgs.find(org => org === orgId) || adminLeagues.find(league => league === leagueId)) {
          return true;
        }
      }
    }
    return false;
  }

  function adminDisabled(disable: boolean) {
    setIsAdminDisabled(disable);
  }

  // *************** Org functions ****************
  const getOrg = async (orgId: string): Promise<Org | null> => {
    if (orgId && userProfile && userProfile.orgIds && userProfile.orgIds.includes(orgId)) {
      const orgRef = doc(firestore, "org/" + orgId);
      const org = await getDoc(orgRef);
      if (org.exists()) {
        return org.data() as Org;
      }
    }
    return null;
  }

  // *************** Application ***************
  const addApplication = async (
    orgId: string, 
    name: string, 
    mailingAddress: string, 
    email: string, 
    ghin: string, 
    phone: string,
    isTest?: boolean,
  ) => {
    // Note: An application can be created by anyone!!!! Yikes! 
    // Todo: remove hard coding of orgId.
    try {
      //
      const applicationRef = doc(applicationsCol);
      const application: Application = {
        name: name,
        mailingAddress: mailingAddress,
        email: email,
        gender: "",
        ghin: ghin,
        itemId: applicationRef.id,
        isActive: false,
        isApproved: false,
        isRejected: false,
        isPaid: false, 
        isTest: isTest || false,
        orgId: orgId,
        phone: phone,
        datetime: Timestamp.now(),
      };
      await setDoc(applicationRef, application);
    } catch (error) {
      alert(error);
    }
  }

  const deleteApplication = async (appId: string) => {
    if (appId) {
      try {
        //}
        const applicationRef = doc(applicationsCol, appId);
        await deleteDoc(applicationRef);
      } catch (error) {
        alert(error);
      }
    }
    else {
      // TODO: Handle error when user isn't available.
    }
  };

  const updateApplication = async (appId: string, isApproved: boolean, isRejected: boolean) => {
    if (appId) {
      try {
        // reference to the document to update
        const applicationRef = doc(applicationsCol, appId);
    
        // Update the value of the application
        await setDoc(applicationRef, {isApproved: isApproved, isRejected: isRejected}, {merge: true});
      } catch (error) {
        alert(error);
      }
    }
    else {
      // TODO: Handle error when user isn't available.
    }
  };

    // *************** Course functions ****************
  const getCourse = async (courseId: string): Promise<Course | null> => {
    const coursesCol = collection(firestore, "course");
    const courseRef = doc(coursesCol, courseId);
    const courseDoc = await getDoc(courseRef);
    if (courseDoc.exists()) {
      return courseDoc.data() as Course;
    }
    return null;
  };

  const addCourse = async (course: Course, isTest?: boolean) => {
    try {
      // document reference to be added
      const coursesCol = collection(firestore, "course");
      const courseRef = doc(coursesCol);
      
      if (auth.currentUser && course) {
        course.creatorId = auth.currentUser.uid;
        course.createTime = CurrentDateTime();
        course.itemId = courseRef.id;
        course.isTest = isTest || false;
        await setDoc(courseRef, course).then(() => {
          return course;
        });
      } else {
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
  
  const deleteCourse = async (course: Course) => {
    try {
      // reference to the document to delete
      const coursesCol = collection(firestore, "course");
      if (course.itemId) {
        const courseRef = doc(coursesCol, course.itemId);
        await deleteDoc(courseRef);
      } else {
        throw new Error("Course itemId not found.");
      }
    } catch (error) {
      alert(error);
    }
  };
  
  const updateCourse = async (course: Partial<Course> ) => {
    try {
      // reference to the document to update
      const coursesCol = collection(firestore, "course");
      const courseRef = doc(coursesCol, course.itemId);
  
      // Update the value of the event item
      setDoc(courseRef, {...course}, {merge: true});
    } catch (error) {
      alert(error);
    }
  };

  // *************** Event Items ****************
  const getEvent = async (orgId: string, eventId: string): Promise<Event | null> => {
    try {
      const eventRef = doc(firestore, "org/" + orgId + "/event/" + eventId);
      const eventDoc = await getDoc(eventRef);
      if (eventDoc.exists()) {
        return eventDoc.data() as Event;
      } else {
        alert("Event not found: " + eventId);
      }
    } catch (error) {
      alert(error);
    }
    return null;
  };

  const addEvent = async (event: Event, isTest?: boolean) => {
    try {
      // document reference to be added
      const eventsCol = collection(firestore, "org/" + event.orgId + "/event");
      const eventRef = doc(eventsCol);
      
      if (auth.currentUser && event && event.leagueId) {
        event.creatorId = auth.currentUser.uid;
        event.datetime = event.days[0].datetime;
        event.itemId = eventRef.id;
        event.isTest = isTest || false;
        await setDoc(eventRef, event).then(() => {
          return event;
        });
      } else {
        alert("Error adding event." + event.name + " " + event.leagueId);
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
  
  const deleteEvent = async (event: Event) => {
    try {
      // reference to the document to delete
      if (event.itemId && event.orgId && isLeagueAdmin(event.leagueId, event.orgId)) {
        const eventRef = doc(firestore, "org/" + event.orgId + "/event/" + event.itemId);
        await deleteDoc(eventRef);
      } else {
        throw new Error("Permission denied. User: " + auth.currentUser?.uid + " Event: " + event.itemId);
      }
    } catch (error) {
      alert(error);
    }
  };
  
  const updateEvent = async (event: Partial<Event>) => {
    try {
      // reference to the document to update
      if (event.orgId && event.itemId && event.leagueId) {
        // extract the orgId and itemId from the league object as changing them is not allowed.
        const { orgId, itemId, leagueId, ...eventUpdates } = event;
        if (isLeagueAdmin(leagueId, orgId)) {
          const eventRef = doc(firestore, "org/" + orgId + "/event/" + itemId);
          updateDoc(eventRef, {...eventUpdates, datetime: event.days ? event.days[0].datetime : Timestamp.now()});
        } else {
          throw new Error("Permission denied. User: " + auth.currentUser?.uid + " Event: " + event.itemId);
        }
      } else {
        throw new Error("updateEvent: orgId or itemId or leagueId not found: orgId: " + 
          event.orgId + " itemId: " + event.itemId + " leagueId: " + event.leagueId);
      }
    } catch (error) {
      alert(error);
    }
  };

  /*
  const copyEventToOrg = async (eventId: string, orgId: string) => {
    console.log("Copying event to org: " + eventId + " " + orgId);
    const eventRef = doc(eventsCol, eventId);
    const eventDoc = await getDoc(eventRef);
    try {
      if (eventDoc.exists()) {
        const event = eventDoc.data() as Event;
        const orgEventRef = doc(orgEventsCol, eventId);
        await setDoc(orgEventRef, event);
        const qReg = query(
          collection(firestore, 'event/' + event.itemId + '/reg'),
          orderBy('datetime')
        ) as Query<Registration>;
        const regSnapshot = await getDocs(qReg);
        regSnapshot.forEach(async (reg) => {
          const regRef = doc(firestore, "org/" + orgId + "/event/" + orgEventRef.id + "/reg/" + reg.id);
          const regData = reg.data() as Registration;
          regData.eventId = orgEventRef.id;
          await setDoc(regRef, regData);
        });
        return true;
      } else {
        console.log("No such document!");
          return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };
  */

  // *************** Inquiry Items ****************
  const addInquiry = async (name: string, email: string, phone: string) => {
    try {
      // document reference to be added
      const inquiryRef = doc(inquiriesCol);

      const inquiry: Inquiry = {
        datetime: Timestamp.now(),
        name: name,
        email: email,
        phone: phone,
        status: "new"
      }

      await setDoc(inquiryRef, inquiry).then(() => {
          return;
        });

    } catch (error) {
      alert(error);
    }
    return; 
  }

  // *************** League functions ****************
  const getLeague = async (orgId: string, leagueId: string): Promise<League | null> => {

    try {
      if (leagueId && orgId) {
        const leagueRef = doc(firestore, "org/" + orgId + "/league/" + leagueId);
        const leagueDoc = await getDoc(leagueRef);
        if (leagueDoc.exists()) {
          return leagueDoc.data() as League;
        }
        alert("League not found: " + leagueId);
        return null;
      } else {
        alert("org not found for league: " + leagueId);
      }
    } catch (error) {
      alert(error);
    }
    return null;
  };

  const addLeague = async (league: League, isTest?: boolean) => {
    try {
      if (auth.currentUser && league) {
        if (league.orgId) {
          const org = await getOrg(league.orgId);
          if (org) {
            const leaguesCol = collection(firestore, "org/" + org.itemId + "/league");
            const leagueRef = doc(leaguesCol);

            league.creatorId = auth.currentUser.uid;
            league.createTime = CurrentDateTime();
            league.itemId = leagueRef.id;
            league.isTest = isTest || false;
            // todo: store orgName
            league.orgId = org.itemId;
            league.orgName = org.name;
            await setDoc(leagueRef, league).then(() => {
              return league;
            });
          } else {
            throw new Error("Org not found: " + league.orgId);
          }
        }
      } else {
        // todo: auth.currentUser not defined.
        throw new Error("auth.currentUser not defined.");
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
      
  const deleteLeague = async (league: League) => {
    try {
      if (league.itemId && league.orgId && adminOrgs.includes(league.orgId)) {
        const leagueRef = doc(firestore, "org/" + league.orgId + "/league/" + league.itemId);
        await deleteDoc(leagueRef);
      }
    } catch (error) {
      alert(error);
    }
  };
  
  const updateLeague = async (league: Partial<League> ) => {
    try {
      if (league.orgId && league.itemId) {
        // extract the orgId and itemId from the league object as changing them is not allowed.
        const { orgId, itemId, ...leagueUpdates } = league;
        if (isLeagueAdmin(itemId, orgId)) {
          const leagueRef = doc(firestore, "org/" + orgId + "/league/" + itemId);
          // 
          setDoc(leagueRef, {...leagueUpdates}, {merge: true});
        } else {
          throw new Error("User is not an admin for the org this league belongs to.");
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  // *************** Season functions ****************
  const getSeason = async (league: League, seasonId: string): Promise<Season | null | undefined> => {
    const orgId = memberships?.find(membership => membership.seasons?.includes(seasonId))?.orgId;

    if (seasonId && orgId) {
      const seasonRef = doc(firestore, "org/" + orgId + "/season/" + seasonId);
      const seasonDoc = await getDoc(seasonRef);
      if (seasonDoc.exists()) {
        return seasonDoc.data() as Season;
      } else {
        return null;
      }
    }
    alert("Season not found: " + seasonId);
    return undefined;
  }

  /*
  const getLeagueSeasons = async (league: League): Promise<Season[] | null | undefined> => {
    try {
      if (league && league.orgId && userProfile && (isGod || (userProfile.orgIds && userProfile.orgIds.includes(league.orgId)))) {
        const seasonsCol = collection(firestore, "org/" + league.orgId + "/season");  
        const q = query(
          seasonsCol,
          and(
            ...(isGod ? [] : [where("orgId", "in", userProfile.orgIds)]),
            where("leagueId", "==", league.itemId),
          ));
        const seasonsSnapshot = await getDocs(q);
        if (seasonsSnapshot.size > 0) {
          const seasons: Season[] = [];
          seasonsSnapshot.forEach((season) => {
            seasons.push(season.data() as Season);
          });
          return seasons;
        } else {
          return null;
        }
      } else {
        alert("getLeagueSeasons: no orgId for league: " + league.itemId);
        return undefined;
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
    return undefined;
  }; */

  // TODO: Should we use Partial for other Adds? Since creatorId, createTime and itemId are all added at this layer...
  const addSeason = async (season: Partial<Season>) => {
    try {
      if (auth.currentUser && season && season.orgId) {
        const org = await getOrg(season.orgId);
        if (org) {
          const seasonRef = doc(firestore, "org/" + org.itemId + "/season/" + season.itemId);

          season.creatorId = auth.currentUser.uid;
          season.createTime = CurrentDateTime();
          season.itemId = seasonRef.id;
          // todo: store orgName
          season.orgId = org.itemId;
          season.orgName = org.name;
          await setDoc(seasonRef, season).then(() => {
            return season;
          });
        } else {
          throw new Error("Org not found: " + season.orgId);
        }
      } else {
        // todo: auth.currentUser not defined.
        throw new Error("auth.currentUser not defined.");
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };

  const addStandingToSeason = async (season: Season, standing: Result) => {
    try {
      // reference to the document to update
      if (season.orgId && isLeagueAdmin(season.leagueId, season.orgId)) {
        const seasonRef = doc(firestore, "org/" + season.orgId + "/season/" + season.itemId);
        const seasonDoc = await getDoc(seasonRef);
        if (seasonDoc.exists()) {
          const season = seasonDoc.data() as Season;
          if (!season.standings) {
            season.standings = [];
          }
          season.standings.push(standing);
          await setDoc(seasonRef, 
          {...season}, {merge: true});
        } else {
          console.log("No such document!");
          throw new Error("Season not found: " + season.itemId);
        }
      } else {
        throw new Error("Permission denied. User: " + auth.currentUser?.uid + " Season: " + season.itemId);
      }
    } catch (error) {
      alert(error);
    }
  }
        
  const deleteSeason = async (season: Season) => {
    try {
      if (season && isLeagueAdmin(season.leagueId, season.orgId)) {
        const seasonRef = doc(firestore, "org/" + season.orgId + "/season/" + season.itemId);
        await deleteDoc(seasonRef);
      } else {
        throw new Error("Permission denied. User: " + auth.currentUser?.uid + " Season: " + season.itemId);
      }
    } catch (error) {
      alert(error);
    }
  };
    
  const updateSeason = async (season: Partial<Season>) => {
    try {
      // Season / Org / League must be provided in the Season.
      if (season.orgId && season.itemId && season.leagueId) { 
        // These fields can't be changed via update because it would move them to entirely different structures 
        // with possibly different sets of games and members. 
        const { orgId, itemId, leagueId, ...seasonUpdates } = season;
        if (isLeagueAdmin(leagueId, orgId)) {
          const seasonRef = doc(firestore, "org/" + orgId + "/season/" + itemId);
          // Update the value of the event item, we always want to make sure itemId is not overwritten.
          setDoc(seasonRef, {...seasonUpdates}, {merge: true});
        } else {
          throw new Error("User is not an admin for the org this league belongs to.");
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  // *************** Membership functions ****************
  const updateMembership = async (membership: Partial<Membership>) => {
    try {
      const { orgId, itemId, ...membershipUpdates } = membership;
      // reference to the document to update
      if (orgId && itemId) {
        const membershipRef = doc(firestore, "org/" + orgId + "/membership/" + itemId);
        // Update the value of the event item
        setDoc(membershipRef, 
          {...membershipUpdates}, {merge: true});
      } else {
        throw new Error("updateMembership: orgId or itemId not found: orgId: " + orgId + " itemId: " + itemId);
      }
    } catch (error) {
      alert(error);
    }
  }

  const addLeagueMembership = async (league: League, memberId: string) => {
    try {
      // reference to the document to update
      const membershipRef = doc(firestore, "org/" + league.orgId + "/membership/" + memberId);
      const membershipDoc = await getDoc(membershipRef);
      if (membershipDoc.exists()) {
        const membership = membershipDoc.data() as Membership;
        if (membership.leagues && membership.leagues.includes(league.itemId)) {
          console.log("Membership already has this league.");
          return;
        } else {
          const updateLeagues = {
            leagues: arrayUnion(membership.leagues, league.itemId)
          };
          updateDoc(membershipRef, updateLeagues).then(() => {
            const profileRef = doc(firestore, "profile/" + memberId);
            updateDoc(profileRef, {leagues: arrayUnion(league.itemId)}).then(() => {
              console.log("Membership updated with league: " + league.itemId);
            });
          });
        }
      } else {
        throw new Error("Membership not found: " + memberId);
      }
    } catch (error) {
      alert(error);
    }
  }

  // *************** Event Registration ****************
  const createNewRegistration = (eventId: string, userId: string, userName: string): Registration => {

    const newRegistration: Registration = { 
      eventId: eventId,
      playerId: userId,
      comment: '',
      datetime: CurrentDateTime(),
      group: 0,
      playerName: userName,
      isEighteen: true,
      isPlayingGame: true,
      isRiding: true,
      isRegistered: false,
      isWaitListed: false,
    };
    return newRegistration;
  };

  const addEventRegistration = async(event: Event, isRegistering: boolean, personToRegister?: Person) => {
    if (auth.currentUser?.uid) {
      try { 
        let reg: Registration;
        let regUserId: string;
        let regUserName: string;

        if (event) {
          if (personToRegister && (isOrgAdmin(event.orgId) || personToRegister.itemId === auth.currentUser.uid)) {
            regUserId = personToRegister.itemId;
            regUserName = personToRegister.name;
          } else if (userProfile) {
            regUserId = auth.currentUser.uid;
            regUserName = userProfile.name;
          } else {
            console.log("User not authorized to register for another user.");
            return null;
          }

          const regRef = doc(firestore, "org/" + event.orgId + "/event/" + event.itemId + "/reg/" + regUserId);
          const regDoc = await getDoc(regRef);
          if (regDoc.exists()) {
            reg = regDoc.data() as Registration;
            reg = {...reg, datetime: CurrentDateTime()};
          } else {
            reg = createNewRegistration(event.itemId, regUserId, regUserName);
          }
          reg.isRegistered = isRegistering;

          // Update the value of the event item
          setDoc(regRef, 
            {...reg }, {merge: true});
            return reg;
        } else {
          console.log("Event is null.");
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
    return null;
  };

  // TODO: This can be merged with registerForEvent
  const updateEventRegistration = async(event: Event, playerId: string, registration: Partial<Registration>) => {
    // A player can update their own registration or an admin can update any registration.
    if (auth.currentUser?.uid && event && ((auth.currentUser.uid === playerId) || isOrgAdmin(event.orgId))) {
      try {
        console.log("wrigint reg: " + event.itemId + " " + playerId + " " + registration.isRegistered);
        const regRef = doc(firestore, "org/" + event.orgId + "/event/" + event.itemId + "/reg/" + playerId);

        await setDoc(regRef, 
          {...registration, playerId: playerId }, {merge: true});
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  }

  // *************** User Profile functions ****************
  const updateProfileDoc = async (id: string, profile: Partial<UserProfile>) => {
    // reference to the document to update
    const profileRef = doc(firestore, "profile", id);

    // Update the value of the profile
    setDoc(profileRef, {...profile}, {merge: true})
    .then (() => {
      console.log("Profile updated: " + profile.name);
    }).catch((error) => {
      alert(error);
    });
  }

  const updateUserProfile = async (id: string, profile: Partial<UserProfile>, picFile?: File | null ) => {
    if (user) {
      // The profile picture has been changed and needs to be uploaded to storage.
      if (picFile) {
        console.log(profile.picture);
        const pictureRef = ref(imagesRef, profile.picture);
        console.log(pictureRef);
        uploadBytes(pictureRef, picFile).then(() => {
          console.log("Picture uploaded.");
          updateProfileDoc(id, profile).then(() => {
            console.log("Profile updated: " + profile.name);
          }).catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // The profile picture has been deleted and needs to be removed from storage.
      } else if (picFile === null) {
        // Delete the picture
        const pictureRef = ref(imagesRef, profile.itemId);
        // Delete the file
        deleteObject(pictureRef).then(() => {
          console.log("Picture deleted.");
          updateProfileDoc(id, {...profile, picture: "", pictureName: ""}).then(() => {
            console.log("Profile updated: " + profile.name);
          }).catch((error) => {
            console.log(error);
          });
        }).catch((error) => {
          console.log(error);
        });
      // No changes to the profile picture, just update the profile.
      } else {
        updateProfileDoc(id, profile).then(() => {
          console.log("Profile updated: " + profile.name);
        }).catch((error) => {
          console.log(error);
        });
      }
    };
  };

  const updateUserIndex = async (ghin: string, index: string) => {
    try {
      const q = query(userProfilesCol, where("ghin", "==", ghin));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        updateUserProfile(doc.id, {index: index});
      }
    } catch (err) {
      console.log(err); 
    }
  }

  const values = {
    // User Profile functions
    userProfilesCol,
    updateUserProfile,
    updateUserIndex,
    // User state functions
    userProfile,
    isUserActive: userProfile !== null ? true : false,
    isUserLoading,
    isAdminDisabled,
    isLeagueAdmin,
    isOrgAdmin,
    adminDisabled,
    adminOrgs,
    adminLeagues,
    memberOrgs,

    // Org functions
    getOrg,
    applicationsCol,
    inquiriesCol,
    
    // Inquiry functions
    addInquiry,

    // Application functions
    addApplication,
    deleteApplication,
    updateApplication,
  
    // Membership functions
    memberships,
    updateMembership,
    addLeagueMembership,
    // getMemberships: (orgId: string) => Promise<Membership[]>;

    // League functions
    getLeague,
    addLeague,
    deleteLeague,
    updateLeague,
    // Season functions
    getSeason,
    addSeason,
    addStandingToSeason,
    deleteSeason,
    updateSeason,

    // Event functions
    getEvent,
    addEvent,
    deleteEvent,
    updateEvent,
    // copyEventToOrg: (eventId: string, orgId: string) => Promise<boolean>;

    // Registration functions
    addEventRegistration,
    updateEventRegistration,

    // Course functions
    getCourse,
    addCourse,
    deleteCourse,
    updateCourse,
  }

  return (
    <DataContext.Provider
      value={values}>
      {children}
    </DataContext.Provider>
  );
}