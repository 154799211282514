import { ReactElement, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import EventEditor from '../components/event/EventEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import Event from '../types/Event';
import { DisplayDate, DisplayDates } from '../utils/utils';

export default function EventEditPage(): ReactElement {
  const { setPageName, setActions } = useApp();
  const { isLeagueAdmin, isOrgAdmin, getEvent, updateEvent } = useData();
  const { orgId, eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    if (eventId && orgId) {
      getEvent(orgId, eventId).then((event) => {
        setEvent(event);
      });
    } else {
      console.error("EventEditPage: eventId and orgId are required");
    }
  }, [eventId, orgId, getEvent]);

  useEffect(() => {
    if (event) {
      const dateText = event.days.length > 1
        ? DisplayDates(
            event.days.map((day) => day.datetime.toDate()),
            event.timezone
          )
        : DisplayDate(event.days[0].datetime.toDate(), event.timezone, 'M/D');

      setPageName(dateText + ' ' + event.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, setActions, setPageName, isOrgAdmin, isLeagueAdmin]);

  const handleSubmit = (event: Event) => {
    if (event) {
      updateEvent(event);
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate('/event/' + eventId, { replace: true });
  };

  if (event && (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId, event.orgId))) {
    return <EventEditor event={event} isNewEvent={false} onCancel={handleCancel} onSubmit={handleSubmit} />;
  } else {
    return <></>;
  }
}
