import { 
  ReactNode, 
  useEffect,
  useState, 
} from "react";

import { 
  and,
  collection, 
  orderBy, 
  Query, 
  query, 
  where 
} from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import Autocomplete from "@mui/material/Autocomplete";
import { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useAuth } from "../../contextProviders/AuthProvider";
import { useData } from "../../contextProviders/DataProvider";
import { 
  firestore 
} from "../../firebase";
import Person from '../../types/Person';

// TODO: Not yet working - not firing onChange properly. Revisit.

export interface PersonAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined,
  > extends Omit<AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'options'> {
  renderInput?: AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>['renderInput'];
  options?: Person[];
  emptyLabel?: string;
  label?: ReactNode;
  selectedPersonId?: string;
  handleOnChange?: (event: React.SyntheticEvent<Element, Event>, value: Person | null, reason: string) => void;
  handleOnInputChange?: (event: React.SyntheticEvent<Element, Event>, value: Person | null, reason: string) => void;
}

export default function PersonAutocomplete<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({ label, ...props }: PersonAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const { emptyLabel, selectedPersonId, handleOnChange } = props;
  const [selectedPerson, setSelectedPerson] = useState<Person | null | undefined>(undefined);

  // TODO: This seems to get reloaded whenever Autocomplete re-renders...
  // TOD: This query needs to be restricted to the context it is being used in. e.g. 
  // An auto-complete for an event should only include league members.
  const qPeople = query(
    collection(firestore, "profile" ),
    and(
      where("isActive", "==", true),
      where("orgIds", "array-contains-any", userProfile?.orgIds),
      where("isTest", "in", [false, isGod])
    ),
    orderBy("name")
  ) as Query<Person>;

  const [people, isLoading, isError] = useCollectionData<Person>(qPeople);

  useEffect (() => {
    if (isError) {
      console.log("Error loading people: " + isError);
    } else if (isLoading) {
      console.log("Loading people");
    } else {
      setSelectedPerson(
        (people && selectedPersonId) ? people.find((person) => 
          person.itemId === selectedPersonId) || null : null);
      console.log("People loaded");
    }
  }, [people, isLoading, isError, selectedPersonId]);

  if (isLoading || !people || selectedPerson === undefined) {
    return(<> </>);
  } else if (isError) {
    return(<>  </>);
  } else {
    console.log("SelectedPerson = " + selectedPerson?.name)
    return (
      <Autocomplete 
        limitTags={2}
        disablePortal
        options={people}
        autoSelect={true}
        clearOnBlur={false}
        value={selectedPerson}
        onChange={ (event, value, reason) => {
          setSelectedPerson(value);
          if (handleOnChange) {
            handleOnChange(event, value, reason);
          }
        }}
        getOptionLabel={(option: Person) => option.name}
        renderOption={(props: object, option: Person /*, state: object */) => (
          <div {...props} >{option.name}</div>)}
        renderInput={(params) => 
          <TextField 
            {...params}
            variant="filled" 
            label={(selectedPerson || !emptyLabel) ? label : emptyLabel} 
          />
        }
      /> 
    );
  }
}