import { useEffect, useMemo, useState } from "react";

import { and, collectionGroup, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Event from "../types/Event";

export type UseEventsProps = {
  filter: "openForSignup" | "upcoming" | "all";
}

export default function useEvents(props: UseEventsProps) {
  const filter = props.filter;
  const { isGod } = useAuth();
  const { userProfile } = useData();
  
  // Add state to track the current time period
  const [timeCounter, setTimeCounter] = useState(0);

// Create a single memoized time value that updates with timeCounter
const currentDatetime = useMemo(() => new Date(Date.now() + timeCounter * 0), [timeCounter]);
  
const next24Hours = useMemo(() => {
  const date = new Date(currentDatetime);
  date.setHours(date.getHours() + 24);
  return date;
}, [currentDatetime]); // Use currentDatetime as the dependency

  // Add effect to update timeCounter every 24 hours
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounter(prev => prev + 1);
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    return () => clearInterval(interval);
  }, []);
  
  // *************** Event loaders ***************
  const qEvents = userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? 
    filter === "openForSignup" ? 
      query(
        collectionGroup(firestore, "event") as Query<Event>,
        and(
          where("orgId", "in", userProfile.orgIds),
          where("isTest", "in", [isGod, false]),
          where("signupClosesAt", ">", currentDatetime),
          where("signupOpensAt", "<", next24Hours)
        ),
        orderBy("datetime", "asc")
      ) : 
    filter === "all" ?
      query(
        collectionGroup(firestore, "event") as Query<Event>, 
        and(where("orgId", "in", userProfile.orgIds),
          where("isTest", "in", [isGod, false])),
        orderBy("datetime", "asc")
      ) :
    filter === "upcoming" ?
      query(
        collectionGroup(firestore, "event") as Query<Event>, 
        and(where("orgId", "in", userProfile.orgIds),
          where("isTest", "in", [isGod, false]),
          where("datetime", ">", currentDatetime)),
        orderBy("datetime", "asc")
      ) : null : null;
  const [events, isEventsLoading, eventsError] = useCollectionData<Event>(qEvents);

  return { events, isEventsLoading, eventsError };   
}
