import { 
  ReactElement, 
} from "react";

import League from '../../types/League';
import GratiCard from "../common/GratiCard";

interface LeagueCardProps {
  league: League;
  variant?: "elevation" | "outlined" | "none";
  isClickable?: boolean;
}

export default function LeagueCard(props: LeagueCardProps): ReactElement {
  const { league, isClickable } = props;
  const variant = props.variant ?? "outlined";

  const StyledType = (): string => {
    return (
      league?.competitionLevel && league.competitionLevel > 4 ? "Competitive" : "Casual"
    );
  }

  return (
    <GratiCard 
      to={isClickable ? `/league/${league.orgId}/${league.itemId}` : undefined} 
      key={league.itemId}
      variant={variant}
      overline={StyledType()}
      avatar={league.logo}
      title={league.name} 
      description={league.description}
    />
  );
}