import React, { 
  ReactElement, 
  useEffect, 
  useRef,
  useState 
} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { useMediaQuery, useTheme } from '@mui/material';

import { useData } from '../../contextProviders/DataProvider';
import useLeagueMemberships from '../../dataProviders/useLeagueMemberships';
import League from '../../types/League';
import Membership from '../../types/Membership';
import Person from '../../types/Person';
import PersonAutocomplete from '../person/PersonAutocomplete';

type LeagueMembersProps = {
  league: League;
  isEditable?: boolean;
};

export default function LeagueMembers(props: LeagueMembersProps): ReactElement {
  const { league, isEditable } = props;

  const { addLeagueMembership } = useData();
  const personSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg')); 
  const [numColumns, setNumColumns] = useState<number>(3);

  const { leagueMemberships, isLeagueMembershipsLoading, isLeagueMembershipsError } = useLeagueMemberships({ league });

  useEffect (() => {
    if (isExtraSmallScreen) {
      setNumColumns(2);
    } else if (isSmallScreen) {
      setNumColumns(3);
    } else if (isMediumScreen) {
      setNumColumns(4);
    } else {
      setNumColumns(6);
    }
  }, [isExtraSmallScreen, isSmallScreen, isMediumScreen]);

  useEffect(() => {
    if (isLeagueMembershipsError) {
      console.error("Error loading league memberships", isLeagueMembershipsError);
    }
  }, [isLeagueMembershipsError]);

  const handleSelectionChange = (event: React.SyntheticEvent, option: Person | null /* reason: string */) => {
    if (option !== null) {
      console.log('Selected in Attendeelist: ' + option?.name);
    } else {
      console.log('Selected in Attendeelist: null');
    }
    personSelected.current = option;
  };

  const handleAddPerson = () => {
    if (personSelected.current !== null) {
      console.log('Adding person: ' + personSelected.current.name);
      addLeagueMembership(league, personSelected.current.itemId);
      setAddPersonFieldValue(null);
    }
  };

  function splitMembershipsIntoGroups (memberships: Membership[] | undefined) : Membership[][] {
    const membershipGroups: Membership[][] = [];
    if (memberships && memberships.length > 0) {
      const chunkSize = Math.ceil(memberships.length / numColumns);
      
      for (let i = 0; i < memberships.length; i += chunkSize) {
        membershipGroups.push(memberships.slice(i, i + chunkSize));
      }
    }
    return membershipGroups;
  }

  // For MemberMember events, assumes that if a partnership has been established,
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  interface MembershipRowProps {
    membership: Membership;
  }

  const MembershipRow = (props: MembershipRowProps): ReactElement => {
    return <Typography key={props.membership.itemId} variant="body1">{props.membership.name}</Typography>;
  };

  if (!isLeagueMembershipsLoading) {
    const membershipGroups = splitMembershipsIntoGroups(leagueMemberships);
    return (
      <Container disableGutters sx={{ flexGrow: 1 }}>
        <Grid container size={{xs: 12}}>
          {leagueMemberships &&
            membershipGroups.map((membershipGroup: Membership[], groupIndex: number) => (
              <Grid key={groupIndex} size={{xs: 6, sm: 4, md: 3, lg: 2}}> 
                {membershipGroup.map((membership: Membership) => (
                  <MembershipRow key={groupIndex+membership.itemId} membership={membership} />
                ))}
              </Grid>))}
        </Grid>
        {isEditable && (
          <Box>
            <Grid container width="100%" alignItems="center" spacing="4">
              <Grid size={{ xs: 9 }}>
                <PersonAutocomplete
                  id="addPerson"
                  size="small"
                  label="Member to add"
                  selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ''}
                  handleOnChange={handleSelectionChange}
                />
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={() => handleAddPerson()}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    );
  } else {
    return <Box />;
  }
}
