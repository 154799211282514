import { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import PasswordIcon from '@mui/icons-material/Password';

import { useApp } from "../contextProviders/AppProvider";
import { passwordReset } from "../firebase";

  function ForgotPassword() {
    const { setPageName } = useApp();
    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);

    useEffect(() => {
      setPageName('Forgot Password');
    }, [setPageName]);

    const handleSubmit = async () => {
      passwordReset(email).then(() => {
        setIsEmailSent(true);
      }).catch((error) => {  
        if (error.code === 'auth/user-not-found') {
          alert('User not found, try again!');
          setEmail('');
        }
      });
    };
    
    return (
      <Box>
        { isEmailSent ?
          <Box>
            <Typography variant="h6" color="primary.text" align="center">
              An email to reset your password has been sent. Check your inbox!
            </Typography>
          </Box> :
          <Box component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}          
          >
            <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
              <TextField
                fullWidth
                required
                id="email"
                label="email"
                aria-label="email"
                placeholder="name@email.com"
                helperText="Email address to reset password"
                onChange={({ target }) => setEmail(target.value)}
              />
              <Button type="submit" variant="contained" startIcon={<PasswordIcon/>}>
                Reset Password
              </Button>
            </Stack>
          </Box>
        }
      </Box>
    )
  }

  export default ForgotPassword