import { 
  ReactElement, 
  useState 
} from 'react';

import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tab from '@mui/material/Tab';

import AddIcon from '@mui/icons-material/Add';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Hourglass from '../components/common/Hourglass';
import OrgApplications from '../components/org/OrgApplications';
import PersonDirectory from '../components/person/PersonDirectory';
import PersonGhinCsvUpdate from '../components/person/PersonGhinCsvUpdate';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';

interface MembersProps {
  initialTab?: string;
}

//const getVisibilityStyle = (hiddenCondition: boolean): any => {
//  if (hiddenCondition) {
//      return {
//          visibility: 'hidden',
//          height: 0,
//      };
//  }
//  return {
//      visibility: 'visible',
//      height: 'inherit',
//  };
//};

export default function Members(props: MembersProps): ReactElement {
  const { isGod } = useAuth();
  const { adminOrgs,  isUserLoading } = useData();

  const [tabSelected, setValue] = useState(props.initialTab ? props.initialTab : "1");

  const handleChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setValue(newTabSelected);
  };

  if (isUserLoading) {
    return <Hourglass />;
  } else if (adminOrgs.length > 0) {
    return (
      <Box sx={{width: "100%"}}>
        <TabContext value={ tabSelected }>
          <Box>
            <TabList 
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab value="1" component={Link} to="/members" label="Members" wrapped />
              <Tab value="2" component={Link} to="/applications" label="Applications" wrapped />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PersonDirectory isActive={true}/>
            {isGod && <PersonGhinCsvUpdate />}
            <Fab variant="extended" color="primary" aria-label="invite member" component={NavLink} to="/invite-members">
              <AddIcon />
              Add member
            </Fab>
          </TabPanel>
          <TabPanel value="2">
            <OrgApplications />
          </TabPanel>
        </TabContext>
      </Box>
    );
  } else {
    return (
      <PersonDirectory isActive={true} />
    );
  }  
}