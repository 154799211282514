import { initializeApp } from "firebase/app";
import { 
  initializeAppCheck, 
  ReCaptchaV3Provider 
} from "firebase/app-check";
import { 
  confirmPasswordReset, 
  connectAuthEmulator,
  getAuth, 
  sendPasswordResetEmail,  
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { 
  connectFunctionsEmulator,
  getFunctions 
} from "firebase/functions";
import { 
  connectStorageEmulator,
  getStorage, 
  ref 
} from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const isEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = isEmulator
  ? {
      apiKey: "fake-api-key",
      authDomain: "localhost",
      projectId: "mahalogolfing",
      storageBucket: "mahalogolfing.appspot.com",
      messagingSenderId: "246675771480",
      appId: "1:246675771480:web:af152b8d42fb79e802fa2d",
    }
  : {
      apiKey: "AIzaSyCJTHfAcgIFziFs2GOSNtGRWa5JiF1T2Do",
      authDomain: "mahalogolfing.firebaseapp.com",
      databaseURL: "https://mahalogolfing-default-rtdb.firebaseio.com",
      projectId: "mahalogolfing",
      storageBucket: "mahalogolfing.appspot.com",
      messagingSenderId: "246675771480",
      appId: "1:246675771480:web:af152b8d42fb79e802fa2d",
      measurementId: "G-DDJ4763QQ8",
    };

export const passwordReset = async (email: string) => {
  return await sendPasswordResetEmail(auth, email)
}

export const confirmThePasswordReset = async (
  oobCode:string, newPassword:string
) => {
  if(!oobCode && !newPassword) return;
  
  return await confirmPasswordReset(auth, oobCode, newPassword)
}

const firebaseApp = initializeApp(firebaseConfig);
// let appCheck = null;

declare global {
// eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

if (isEmulator || process.env.NODE_ENV === "development") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  console.log('Fake App Check Debug Token');
} else {
  // Production App Check setup
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lck4lIpAAAAAKLUo-cIE4rKsSIVuzjLx_f_Jq2s'),
    isTokenAutoRefreshEnabled: true
  });
}

//if (!process.env.NODE_INV || process.env.NODE_ENV === "development" || isEmulator) {
//  if (typeof window !== 'undefined') {
//    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN;
//  } 
//}

// For development on a local host, no need to activate app check.
//if (typeof window !== "undefined" && !appCheck) {
//  appCheck = initializeAppCheck(app, {
//    provider: new ReCaptchaV3Provider("6Lck4lIpAAAAAKLUo-cIE4rKsSIVuzjLx_f_Jq2s"),
//      // Optional argument. If true, the SDK automatically refreshes App Check
//      //  tokens as needed.
//      isTokenAutoRefreshEnabled: true
//  });
//}
 
const auth = getAuth(firebaseApp);
const firestore = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache(
    /*settings*/{ tabManager: persistentMultipleTabManager() }
  )
});
const cloudFunctions = getFunctions(firebaseApp);
// const appCheckInstance = appCheck;
// export const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);
const storageRef = ref(storage);
const imagesRef = ref(storage, "images");

// Connect to emulators if environment variable is set
if (isEmulator) {
  console.log('Using Firebase Emulators');
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(cloudFunctions, 'localhost', 5001);
}

export { auth, firestore, cloudFunctions, /* appCheckInstance, */ storage, storageRef, imagesRef };
