import { ReactElement } from "react";

import { ref } from "firebase/storage";

import { Link } from "react-router-dom";

import { useDownloadURL } from "react-firebase-hooks/storage";

import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ConstructionIcon from "@mui/icons-material/Construction";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";

import { imagesRef } from "../../firebase";
import League from '../../types/League';
import { CompetitiveLevels } from '../../utils/Compete';

interface LeagueRowProps {
  league: League,
  actions?: ReactElement<typeof Stack>,
  isDividerDisplayed?: boolean
}

export const LeagueListItem = (props: LeagueRowProps ) : ReactElement => {

  const league = props.league;
  const isDividerDisplayed = props.isDividerDisplayed ?? false;
  const [imageUrl] = useDownloadURL((league && league.logo && league.logo.length > 0) ? ref(imagesRef, league.logo) : null);

  const StyledLeagueAvatar = (): ReactElement => {
    return (<Avatar alt={league.name} src={imageUrl} sx={{height: 48, width: 48, textAlign: "center", alignItems: "center", justifyContent: "center", color: "primary.onContainer", backgroundColor: "primary.container"}}>
      <SportsGolfIcon sx={{color: "primary.onContainer", backgroundColor: "primary.container"}}/>
    </Avatar>);
  }
  const StyledCompetitiveness = (): ReactElement => {
    if (league.competitionLevel) {
      return (
        <span>
          <Typography fontSize="small" color="primary">
            {CompetitiveLevels[league.competitionLevel]}
          </Typography>
        </span>);
    } else {
      return <span><Typography fontSize="small" color="primary">None</Typography></span>;
    }
  }

  return (
    <ListItem 
      sx={{ pl: 0 }}
      component={Link} 
      to={`/league/${league.orgId}/${league.itemId}`} 
      divider={isDividerDisplayed} 
      className={league.isTest ? "test" : ""}>
      <ListItemAvatar>
        <StyledLeagueAvatar />
      </ListItemAvatar>
      <ListItemText 
        primary={league.name} 
        primaryTypographyProps={{
          variant: "title", 
          color: "surface.contrastText"
        }}
        secondary={league.description}
        secondaryTypographyProps={{
          variant: "body2",
          color: "surface.onVariant"
        }}
      />
      {league.isTest && <ConstructionIcon fontSize="small" color="error" />}
      <StyledCompetitiveness />
    </ListItem>
  );
}