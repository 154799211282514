export const GOOGLE_PLACES_OLD_API_KEY = "AIzaSyAEDNjXUP3iVPvgWVfHjxMFO8ZyZ8UhQh0";
export const GOOGLE_PLACES_API_KEY = "AIzaSyBwpVS6n-nvwK7neQ0z-pSa7o0l_Q4AdMI";

export const loadPlacesApi = (isNewApi = false) => {
  const apiKey = isNewApi ? GOOGLE_PLACES_API_KEY : GOOGLE_PLACES_OLD_API_KEY;
  const placesURL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places`;

  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(placesURL) === 0) {
      return scripts[i];
    }
  }

  const googleMapScript = document.createElement('script');
  googleMapScript.src = placesURL;
  console.log(googleMapScript);
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};