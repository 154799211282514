import { 
  ReactElement, 
  useEffect, 
  useState
} from "react";

import { useNavigate, useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import EditIcon from '@mui/icons-material/Edit';

import Hourglass from "../components/common/Hourglass";
import CourseCard from "../components/course/CourseCard";
import CourseEditor from "../components/course/CourseEditor";
import { useApp } from "../contextProviders/AppProvider";
import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import Course from "../types/Course";

interface CourseProps {
  edit?: boolean;
}
export default function CoursePage(props: CourseProps): ReactElement {
  const [isEditMode, setIsEditMode] = useState(props.edit ? props.edit : false);
  const { setActions, setPageName } = useApp();
  const { isGod } = useAuth();
  const { getCourse, isOrgAdmin, } = useData();

  const { courseId } = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState<Course | null>(null);

  useEffect(() => {
    if (courseId) {
      getCourse(courseId).then((course) => {
        setCourse(course);
      });
    }
  }, [courseId, getCourse]);

  useEffect(() => {
    if (course) {
      setPageName(course.name);
      if (isGod || (course.orgId && isOrgAdmin(course.orgId))) {
        setActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, isGod, isOrgAdmin, setActions, setPageName]);

  const handleEditClick = () => {
    if (course) {
      navigate('/course-edit/' + course.itemId, { replace: true });
    }
    setIsEditMode(true);
  };


  const CourseDetails = (): ReactElement => {
    if (course) {
      return (
        <Stack spacing={2}>
          <CourseCard course={course} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  /* const Holes = (): ReactElement => {
    if (course) {
      return (<Box></Box>);
    } else {
      return (<Box></Box>);
    }
  };*/

  if (course) {
    if (isGod || (course.orgId && isOrgAdmin(course.orgId))) {
      return (
        <>
          {isEditMode ? <CourseEditor course={course} isNewCourse={false} /> : <CourseDetails />}
        </>
      );
    } else {
      return <CourseDetails />;
    }
  } else {
    return (<Hourglass />);
  }
};