import { ReactElement } from 'react';

import Box from '@mui/material/Box';

import { List } from '@mui/material';

import useCourses from '../../dataProviders/useCourses';
import Course from '../../types/Course';
import Hourglass from '../common/Hourglass';

import { CourseListItem } from './CourseListItem';

export const CourseDirectory = (): ReactElement => {
  const { courses, isCoursesLoading, coursesError } = useCourses({});

  if (isCoursesLoading) {
    return <Hourglass />;
  } else if (coursesError) {
    return <Box width="100vw" />;
  } else if (courses) {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        { courses.flatMap((course: Course) => {
            return (<CourseListItem key={course.itemId} course={course} isDividerDisplayed={true} />);
          })
        }
      </List>
    );
  } else {
    return <></>;
  }
};
