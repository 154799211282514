import { 
  ReactElement,
} from  "react";

import { Timestamp } from "firebase/firestore";

import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import dayjs, { Dayjs } from "dayjs";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import useCourses from '../../dataProviders/useCourses';
import Course from "../../types/Course";
import EventDay from "../../types/EventDay";
import { 
  DisplayDate,
  ToDayJS 
} from "../../utils/utils";

export interface EventTimeEditorProps {
  days: EventDay[], 
  defaultTime?: Dayjs,
  timezone?: string, 
  isGolfEvent?: boolean, 
  onChange: (newDays: EventDay[]) => void;
}

export default function EventTimeEditor (props: EventTimeEditorProps): ReactElement {

  const days = props.days;
  const defaultTime = props.defaultTime ? props.defaultTime : dayjs("2000-01-01T09:00:00");

  const timezone = props.timezone ? props.timezone : "America/Los_Angeles";
  const isGolfEvent = props.isGolfEvent ? props.isGolfEvent : true;
  const onChange = props.onChange;
  
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs")); 
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isSmallScreen = isXS || isSm;

  const { courses, isCoursesLoading, coursesError } = useCourses({});

  function CourseMenu(): ReactElement[] {
    if (isCoursesLoading) {
      return (
        [<MenuItem key="loading" value="loading">
          Loading...
        </MenuItem>]
      );
    } else if (coursesError) {
      return (
        [<MenuItem key="error" value="error">
          Error loading courses
        </MenuItem>]
      );
    } else if (courses) {
      return courses.map((course: Course) => (
        <MenuItem key={course.itemId} value={course.itemId}>
          {course.name}
        </MenuItem>
      ));
    } else {
      return [<MenuItem key="empty" value="empty">---</MenuItem>];
    }
  }

  if (days.length === 0) {
    return (
      <Stack>
        <Typography>Start Time</Typography>
        <TimePicker
          value={defaultTime} />
      </Stack>
    );
  } else {
    return (
      (<Stack spacing={2} sx={{pb: 4}}>
        <Typography variant="title" color="primary.secondary">
          Daily Start Times
        </Typography>
        {days.map((day, i) => (
          <Grid key={i} container spacing={2}>
            <Grid size={{xs: 12, lg: 6}}>
              <Stack direction="row" spacing={2} sx={{display: "flex", alignItems: "center"}}>
                <TimePicker
                  label={DisplayDate(day.datetime.toDate(), timezone, isSmallScreen ? "M/D/YY" : "MMM DD, YYYY")}
                  value={ToDayJS(days[i].datetime, timezone)} 
                  onChange={(target) => {
                    if (target != null) {
                      const newDays = [...days];
                      newDays[i].datetime = Timestamp.fromDate(target.toDate());
                      onChange(newDays);
                    }
                  }}
                />
                {(day.isGolf || isGolfEvent) &&
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Switch
                          checked={days[i].isShotgun ? days[i].isShotgun : false}
                          name="shotgun"
                          onChange={({ target }) => {
                            const newDays = [...days];
                            newDays[i].isShotgun = target.checked;
                            onChange(newDays);
                          }}
                        />
                      } 
                      label="Shotgun" 
                      labelPlacement={isSmallScreen ? "top" : "start"}
                    />
                  </FormGroup>
                }
              </Stack>
            </Grid>
            <Grid key={i} size={{xs: 12, lg: 6}}>
              {(day.isGolf || isGolfEvent) && courses && courses.length > 0 && (
                <TextField
                  id="location"
                  label="Location"
                  autoFocus={false}
                  select
                  aria-label="location"
                  aria-required="false"
                  value={days[i].courseId ? days[i].courseId : ""}
                  fullWidth
                  onChange={({ target }) => {
                    const newDays = [...days];
                    newDays[i].courseId = target.value;
                    onChange(newDays);
                  }}
                >
                  {CourseMenu()}
                </TextField>
              )}

            </Grid>
            {i < days.length - 1 && <Divider sx={{ width: "100%"}}/>}
          </Grid>
        ))}
      </Stack>));
  }
}