import { 
  ReactElement, 
  useEffect 
} from "react";

import { Timestamp } from "firebase/firestore";

import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import EventEditor from "../components/event/EventEditor";
import { useApp } from "../contextProviders/AppProvider";
import { useData } from "../contextProviders/DataProvider";
import Event from "../types/Event";

export default function EventCreatePage(): ReactElement {
  const { orgId, leagueId, seasonId } = useParams();

  const { setPageName } = useApp();
  const { addEvent, adminLeagues, adminOrgs } = useData();
  const navigate = useNavigate();

  useEffect (() => {
    setPageName("Create Event");
  }, [setPageName]);

  const handleSubmit = (event: Event) => {
    if (event) {
      addEvent(event);
    };
    navigate(-1);
  }

  const createNewEvent = (): Event => {
    const newEvent: Event = { 
      name: '', 
      creatorId: '',
      days: [{courseId: '', datetime: Timestamp.now(), duration: 60, isGolf: true, isShotgun: false}],
      datetime: Timestamp.now(),
      endTime: Timestamp.now(),
      description: '',
      isGolf: true,
      isShotgun: false,
      isTest: false,
      itemId: '',
      leagueId: leagueId ?? "",
      leagueName: "",
      logo: '',
      maxPlayers: 0,
      numTeeTimes: 0,
      orgId: orgId ?? "",
      seasonId: seasonId ?? "",
      seasonName: "",
      signupOpensAt: Timestamp.now(),
      signupClosesAt: Timestamp.now(),
      socialLocation: "",
      timezone: "America/Los_Angeles"
    };
    return newEvent;
  };

  if (adminOrgs.length > 0 || adminLeagues.length > 0) {
    const event = createNewEvent();

    return (
      <Box>
        <EventEditor 
          event={event} 
          isNewEvent={true} 
          onSubmit={handleSubmit}
        />
      </Box>
    );
  } else {
    navigate("/");
    return <></>;
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&