import { ReactNode } from 'react';

import { LoadScript } from '@react-google-maps/api';

import { GOOGLE_PLACES_API_KEY } from "../utils/GoogleMapsUtils";
import { useOnlineStatus } from '../utils/useOnlineStatus';

// Define libraries array outside component
const GOOGLE_MAPS_LIBRARIES: ["places"] = ["places"];

interface MapsProviderProps {
  children: ReactNode;
}

export function MapsProvider({ children }: MapsProviderProps) {
  const { isOnline } = useOnlineStatus();
  
  if (!isOnline) {
    return <div>Maps functionality unavailable while offline</div>;
  }
  
  return (
    <LoadScript 
      googleMapsApiKey={GOOGLE_PLACES_API_KEY} 
      libraries={GOOGLE_MAPS_LIBRARIES}
      loadingElement={<div>Loading Maps...</div>}
      onError={(error) => console.error('Google Maps loading error:', error)}
    >
      {children}
    </LoadScript>
  );
}