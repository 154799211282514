import { 
  ReactElement,
  useCallback,
  useEffect,
  useState, 
} from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";

import useEvents from "../../dataProviders/useEvents";
import Event from "../../types/Event";
import { IsEventOver } from "../../utils/utils";
import Hourglass from "../common/Hourglass";

import EventListItem from "./EventListItem";
import EventScheduleSection from "./EventScheduleSection";

export interface EventScheduleProps {
  isSortedbyLeague?: boolean;
  isUpcoming: boolean;
}

export default function EventSchedule(props: EventScheduleProps): ReactElement {
  const isSortedByLeague = props.isSortedbyLeague;
  const isUpcoming = props.isUpcoming;
  const [sortedEvents, setSortedEvents] = useState<{ [leagueId: string]: EventsBySeason } | null>(null);
  const { events, isEventsLoading, eventsError } = useEvents({filter: isUpcoming ? "upcoming" : "all"});

  const sortEvents = useCallback(() => {
    if (events && isSortedByLeague) {
      const sortedEvents = {} as { [leagueId: string]: EventsBySeason };
  
      for (const event of events) {
        const leagueId = event.leagueId;
        const leagueName = event.leagueName;
        const seasonId = event.seasonId ? event.seasonId : "0";
        const seasonName = event.seasonName ? event.seasonName : "";

        if (!sortedEvents[leagueId]) {
          sortedEvents[leagueId] = { name: leagueName, seasons: {} };
        }

        if (!sortedEvents[event.leagueId].seasons[seasonId]) {
          sortedEvents[event.leagueId].seasons[seasonId] = { name: seasonName, events: [] };
        } 

        sortedEvents[leagueId].seasons[seasonId].events.push(event);

      }
      return sortedEvents;
    } else {
      return null;
    }
  }, [events, isSortedByLeague]);

  useEffect(() => {
    if (isEventsLoading) {
      console.log("Loading events");
    } else if (eventsError) {
      console.error("Error loading events", eventsError);
    } else if (events) {
      if (isSortedByLeague) {
        const sortedEvents = sortEvents();
        if (sortedEvents) {
          setSortedEvents(sortEvents());
        }
      }
    }
  }, [isEventsLoading, isSortedByLeague, eventsError, events, sortEvents]);

  interface EventsBySeason {
    name: string; 
    seasons: {
      [seasonId: string]: {
        name: string;
        events: Event[];
      };
    };
  }

  if (events) {
    if (isSortedByLeague) {
      if (sortedEvents) {
        return (
          <Box sx={{ p: 0}}>
            <List
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                p: 0,
                '& ul': { padding: 0 },
                '& li': { padding: 0 },
              }}
            >
              {Object.keys(sortedEvents).map((leagueId: string) => {
                return (
                  <ListItem disableGutters key={`section-${leagueId}`} sx={{pl: 0, width: "100%"}}>
                    <List 
                      subheader={
                        <ListSubheader>
                          <Typography variant="h6" color="primary.main">
                            {sortedEvents[leagueId].name}
                          </Typography>
                        </ListSubheader>
                      }
                      sx={{pl: 0, width: "100%"}}
                    >
                      { sortedEvents[leagueId].seasons && Object.keys(sortedEvents[leagueId].seasons).map((seasonId: string) => {
                        return (
                          <EventScheduleSection 
                            title={sortedEvents[leagueId].seasons[seasonId].name} 
                            events={sortedEvents[leagueId].seasons[seasonId].events} 
                            isLeagueDisplayed={false} 
                            isUpcoming={isUpcoming} 
                            sectionId={seasonId} 
                            key={`section-${leagueId}-${seasonId}`}/>
                        )
                      })}
                    </List>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        );
      } else {
        console.log("Sorting the events failed.")
        return <> </>
      }
    } else { 
      return (
        <Box>
          <List
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'auto',
              p: 0,
              '& ul': { padding: 0 },
              '& li': { padding: 0 },
            }}
            subheader={<li />}
          >
            { events.flatMap((event: Event) => {
              if (IsEventOver(event.endTime) !== props.isUpcoming) {
                return (<EventListItem key={event.itemId} event={event} isLeagueDisplayed={true}/>);
              } else {
                return null;
              }
            })}
          </List>
        </Box>
      );
    }
  } else {
    return <Hourglass />
  }
}