import { 
  ReactElement, 
} from "react";

import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Course from '../../types/Course';
import GratiCard from "../common/GratiCard";

interface CourseCardProps {
  course: Course;
  variant?: "elevation" | "outlined" | "none";
  isClickable?: boolean;
}

export default function CourseCard(props: CourseCardProps): ReactElement {
  const { course } = props;
  const variant = props.variant ?? "outlined";
  const isClickable = props.isClickable ?? true;

  const StyledType = (): string => {
    return (
      course.membership ? course.membership : ""
    );
  }

  const ActionBar = (): ReactElement => {
    return (
      <Stack spacing={2}>
        {course.usgaCourseId &&
          <Button 
            variant="contained" 
            color="secondary" 
            component="a" 
            target="_blank"
            href={`https://ncrdb.usga.org/courseTeeInfo?CourseID=${course.usgaCourseId}`}
            endIcon={<OpenInNewIcon />}
          >
            USGA Course Info
          </Button>
        }
      </Stack>
    );
  }

  return (
    <GratiCard 
      to={isClickable ? `/course/${course.itemId}` : undefined} 
      key={course.itemId}
      variant={variant}
      overline={StyledType()}
      avatar={course.logo}
      title={course.name} 
      description={"Par: " + course.par}
      placeId={course.googlePlaceId}
      footer={["Timezone: " + course.timezone]}
      actionbar=<ActionBar />
    />
  );
}