import { ReactElement, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import OrgSelector from '../components/org/OrgSelector';
import { useData } from '../contextProviders/DataProvider';
import { validateEmail } from '../utils/validators';

type Invitation = {
  name: string;
  mailingAddress: string;
  email: string;
  ghin: string;
  isEmailValid: boolean;
  isEmailInUse: boolean;
  isEmailEdited: boolean;
  orgId: string;
  phone: string;
};

export default function InviteMembers(): ReactElement {
  const navigate = useNavigate();
  const { adminOrgs, addApplication } = useData();

  const [invitation, setInvitation] = useState<Invitation>({
    name: '',
    mailingAddress: '',
    email: '',
    ghin: '',
    isEmailValid: false,
    isEmailInUse: false,
    isEmailEdited: false,
    orgId: '',
    phone: '',
  });

  // TODO: Enable selecting from one of several orgs.
  useEffect(() => {
    if (adminOrgs.length > 0) {
      setInvitation((invitation) => ({ ...invitation, orgId: adminOrgs[0] }));
    }
  }, [adminOrgs]);

  const handleSubmit = async () => {
    await addApplication(
      invitation.orgId,
      invitation.name,
      invitation.mailingAddress,
      invitation.email,
      invitation.ghin,
      invitation.phone
    ).then(() => {
      navigate('/applications');
    });
  };

  const handleEmailChange = (val: string) => {
    setInvitation((invitation) => ({
      ...invitation,
      email: val,
      isEmailEdited: false,
      isEmailValid: validateEmail(val),
      isEmailInuse: false,
    }));
  };

  if (adminOrgs.length > 0) {
    return (
      <Stack component="form" spacing={2} onSubmit={(e) => { e.preventDefault(); handleSubmit();}}>
        <Typography gutterBottom variant="headline">
          Invite Members
        </Typography>
        <TextField
          required
          autoFocus={true}
          id={'name'}
          label="Name"
          aria-label="name"
          fullWidth
          onChange={({ target }) =>
            setInvitation((invitation) => ({
              ...invitation,
              name: target.value,
            }))
          }
        />
        <TextField
          required
          error={invitation.isEmailEdited && invitation.isEmailValid === false}
          id={'email'}
          label={invitation.isEmailEdited && invitation.isEmailValid === false ? 'Error' : 'Email'}
          onBlur={(e) =>
            setInvitation((invitation) => ({
              ...invitation,
              isEmailEdited: e.target.value.length > 0 ? true : false,
            }))
          }
          aria-label="Email"
          helperText={
            invitation.isEmailEdited && invitation.isEmailValid === false
              ? invitation.isEmailInUse
                ? 'Email already in use.'
                : 'Invalid email address'
              : ''
          }
          fullWidth
          value={invitation.email}
          onChange={(e) => handleEmailChange(e.target.value)}
        />
        <OrgSelector value={invitation.orgId} handleChange={(orgId) => setInvitation({ ...invitation, "orgId": orgId })} />
        <Button
          id="invite"
          aria-label="submit invitation"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Invite
        </Button>
      </Stack>
    );
  } else {
    // navigate("/");
    return <></>;
  }
}
