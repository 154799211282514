import { ReactElement } from "react";

import { Link } from "react-router-dom";

import Stack from "@mui/material/Stack";

import { Alert, List, ListItem, Typography } from "@mui/material";

import useLeagues from "../../dataProviders/useLeagues";
import Person from "../../types/Person";
import Hourglass from "../common/Hourglass";

interface PersonLeagueListProps {
  person: Person,
}

export default function PersonLeagueList(props: PersonLeagueListProps): ReactElement {
  const { person } = props; 
  const { leagues, isLeaguesLoading, leaguesError } = useLeagues();

  if (isLeaguesLoading) {
    return <Hourglass />;
  } else if (leaguesError) {
    return <Alert severity="error">Error loading leagues {leaguesError.message}</Alert>;
  } else if (person && leagues && leagues.length > 0) {
    return (
      <Stack direction="column">
        <Typography variant="label">Leagues</Typography>
        <List
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            p: 0,
            '& ul': { padding: 0 },
            '& li': { padding: 0 },
          }}
          subheader={<li />}
        >
          {person.leagueIds?.map((leagueId) => {
              const league = leagues.find((league) => league.itemId === leagueId);
              return league && (
                <ListItem 
                  key={leagueId} 
                  component={Link} 
                  to={`/league/${league.orgId}/${leagueId}`} 
                  sx={{ color: 'surface.contrastText' }}
                >
                  {league.name}
                </ListItem>
              );
            })}
        </List>
      </Stack>
    );
  } else {
    return <></>;
  }
}