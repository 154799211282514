import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { useData } from '../../contextProviders/DataProvider';
import useSeasons from '../../dataProviders/useSeasons';
import League from '../../types/League';
import Season from '../../types/Season';
import {} from '../../utils/Compete';
import { CurrentDate } from '../../utils/utils';
import Hourglass from '../common/Hourglass';

import SeasonEditor from './SeasonEditor';

interface SeasonsProps {
  league: League;
}

export default function Seasons(props: SeasonsProps): ReactElement {
  const { league } = props;
  const { addSeason, deleteSeason, updateSeason } = useData();

  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({ league: league });

  const handleSeasonAdd = () => {
    addSeason({
      endTime: CurrentDate(),
      leagueId: league.itemId,
      leagueName: league.name,
      orgId: league.orgId,
      orgName: league.orgName,
      name: '',
      startTime: CurrentDate(),
    });
  };

  const handleSeasonChange = (season: Season, reason: string) => {
    switch (reason) {
      case 'update':
        console.log('Update ', season.itemId);
        try {
          updateSeason(season);
        } catch (error) {
          console.error('Error updating season: ', error);
        }
        break;
      case 'delete':
        try {
          deleteSeason(season);
        } catch (error) {
          console.error('Error deleting season: ', error);
        }
        console.log('Delete ', season.itemId);
        break;
      default:
        console.error('Unknown reason: ', reason);
    }
  };

  if (isSeasonsLoading) {
    return <Hourglass />
  } else if (seasonsError) {
    return <Alert severity="error">Error loading seasons: {seasonsError.message}</Alert>
  } else if (seasons) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Typography variant="headline">
            Seasons
          </Typography>
          <IconButton color="secondary" size="medium" onClick={() => handleSeasonAdd()}>
            <AddIcon />
          </IconButton>
        </Stack>
        {seasons &&
          seasons.map((season) => <SeasonEditor key={season.itemId} season={season} onChange={handleSeasonChange} />)}
      </Stack>
    );
  } else {
    return <></>;
  }
}