import { 
  useState, 
  useEffect,
  useRef
} from 'react';

import Box from '@mui/material/Box';

import { SxProps } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { useOnlineStatus } from '../../utils/useOnlineStatus';

import GratiMapFallback from './GratiMapFallback';

const containerStyle = {
  width: '100%',
  height: '100%',
};

export interface GratiMapProps {
  placeId: string;
  sx?: SxProps;
}

export default function GratiMap(props: GratiMapProps) {
  const { placeId, sx } = props;
  const { isEmulator, isOnline } = useOnlineStatus();
  const defaultCenter = { lat: 37.7749, lng: -122.4194 };
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (mapRef.current && mapCenter) {
      mapRef.current.setCenter(mapCenter);
    }
  }, [mapCenter]);

  // Only run place lookup when both placeId and maps are loaded
  useEffect(() => {
    if (!isOnline || isEmulator) {
      return;
    }

    const getPlaceDetails = () => {
      if (!placeId || !google?.maps.places) {
        console.log('Not ready:', { placeId, googleMaps: !!google?.maps.places });
        return;
      }

      const mapDiv = document.createElement('div');
      const placesService = new google.maps.places.PlacesService(mapDiv);
      
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ['geometry']
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
            setMapCenter(place.geometry.location.toJSON());
          } else {
            console.error('Error getting place details:', status);
          }
        }
      );
    };

    getPlaceDetails();
  }, [placeId, isOnline, isEmulator]);

  return (
    <Box sx={sx}>
      { (isOnline && !isEmulator) ?
        <GoogleMap
          mapContainerStyle={containerStyle}
        center={mapCenter || defaultCenter } // Default to San Francisco
        zoom={10}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
          {mapCenter && <Marker position={mapCenter} />}
        </GoogleMap>
      : 
        <GratiMapFallback />
      }
    </Box>
  );
};