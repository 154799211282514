import { ReactElement } from 'react';

import { NavLink } from "react-router-dom";

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import { CourseDirectory } from '../components/course/CourseDirectory';
import { useAuth } from '../contextProviders/AuthProvider';

export default function Courses(): ReactElement {
  const { isGod } = useAuth();
  
  return (
    <>
    <CourseDirectory />
    { isGod &&
      <Fab variant="extended" color="primary" aria-label="add course" component={NavLink} to="/course-create">
        <AddIcon />
        Add course
      </Fab>}
    </>
    );  
}