import React, { 
  useState, 
} from "react";

import { 
  Link, 
  useNavigate
} from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Theme } from "@mui/material/styles";
import { SxProps } from '@mui/material/styles';
import SvgIcon from "@mui/material/SvgIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import MenuIcon from "@mui/icons-material/Menu";

import { 
  useApp
} from "../../contextProviders/AppProvider";
import { useAuth } from "../../contextProviders/AuthProvider";
import { useData } from "../../contextProviders/DataProvider";

import GratiSettingsMenu from "./GratiSettingsMenu";

interface GratiAppBarProps {
  sx?: SxProps<Theme>;
}

export default function GratiAppBar(props: GratiAppBarProps) {
  const sx = props.sx;
  const navigate = useNavigate();
  const { actions, getPageTitle, isHomePage, pageContexts } = useApp();
  const { user } = useAuth();
  const { adminOrgs, isOrgAdmin } = useData();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleBackClick = () => {
    if (isHomePage) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar 
      position="fixed" 
      sx={[
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Toolbar disableGutters>
        <Box className="NavMenu" sx={{ flexGrow: isHomePage ? 1 : 0, display: { xs: "flex" } }}>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={isHomePage ? handleOpenNavMenu : handleBackClick}
            edge="start"
            className="AppBarIcon"
            sx={{display: {xs: "flex", md: isHomePage ? "none" : "flex"} }}
          >
            {isHomePage ? <MenuIcon /> : <ArrowBackIcon />}
          </IconButton>
          {isHomePage &&
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
            {pageContexts.map((page) => (
              ((page.showLogin && user && (!page.showOnlyAdmin || (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])))) || 
                (page.showLogout && !user)) ?
                <MenuItem 
                  component={Link}
                  key={page.name}
                  to={page.to}
                  onClick={handleCloseNavMenu}>
                 <Typography textAlign="center" className="AppBarText">{page.name}</Typography>
                </MenuItem> : null
            ))}
            </Menu>
          }
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" className="Headline">
          {isHomePage && 
            <IconButton className="AppBarIcon">
              <GolfCourseIcon className="AppBarIcon"/>
            </IconButton>}
          <Typography
            sx={{
              display: { xs: "flex" },
              flexGrow: 0,
            }}
            className={isHomePage ? "AppBarText-home" : "AppBarText"}
          >
            {getPageTitle()}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center">
            {actions.map((action) => (
              <IconButton
                key={action.name}
                onClick={action.handleClick}
                className="AppBarIcon"
              >
                <SvgIcon component={action.icon} />  
              </IconButton>
            ))}
          </Stack>
        <GratiSettingsMenu sx={{flexGrow: 0}}/>
      </Toolbar>
    </AppBar>
  );
}