import { ReactElement, useEffect } from 'react';

import { and, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import UserProfile from '../../types/UserProfile';
import Hourglass from '../common/Hourglass';

import PersonListItem from './PersonListItem';

interface PersonDirectoryProps {
  isActive: boolean;
}

export default function PersonDirectory(props: PersonDirectoryProps): ReactElement {
  const { isGod } = useAuth();
  const { adminOrgs, isOrgAdmin, userProfilesCol, userProfile } = useData();

  const qMembers = userProfile?.orgIds ? query(
    userProfilesCol,
    and(
      where('isActive', '==', props.isActive),
      where('isTest', 'in', [false, isGod]),
      where('orgIds', 'array-contains-any', userProfile.orgIds),
    ),
    orderBy('name')
  ) as Query<UserProfile> : null;

  const [members, isLoading, isError, snapshot] = useCollectionData<UserProfile>(qMembers);

  useEffect(() => {
    if (isLoading) {
      console.log("members: loading");
    } else if (isError) {
      console.log("members: error: " + isError);
      console.log(snapshot);
    } else if (members) {
      console.log("members found: " + members.length);
    }
  }, [members, isLoading, isError, snapshot]);

  if (isLoading) {
    return <Box width="100vw" />;
  } else if (isError) {
    return <Box width="100vw" />;
  } else if (members) {
    return (
      <Container disableGutters className="SectionContainer">
        <Typography align="left" gutterBottom variant="headline">
          {adminOrgs && isOrgAdmin(adminOrgs[0])
            ? props.isActive
              ? 'Active Members'
              : 'Guests'
            : 'Member Directory'}
        </Typography>
        <Box>
          <List
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'auto',
              p: 0,
              '& ul': { padding: 0 },
              '& li': { padding: 0 },
            }}
            subheader={<li />}
          >
            { members.flatMap((member: UserProfile) => {
                return (
                  <PersonListItem
                    key={member.itemId} 
                    person={member} 
                    isClickable={true}
                    isDividerDisplayed={true}
                  />
                );
              }
            ) }
          </List>
        </Box>
      </Container>
    );
  } else {
    return <Hourglass />;
  }
};
